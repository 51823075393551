// Temporary font export. Will follow up to have this generated via build script

const fontFamilyBackup = {
    body: 'Helvetica Neue, helvetica, arial, sans-serif',
    header: 'Georgia, serif',
};

export const fontFamily = {
    bodyItalic: (size: string): string =>
        `300 italic ${size} proxima-nova, ${fontFamilyBackup.body}`,
    bodyHeavy: (size: string): string => `600 ${size} proxima-nova, ${fontFamilyBackup.body}`,
    header: (size: string): string => `${size} Cardinal Classic Short, ${fontFamilyBackup.header}`,
};

export const bodyFontSize = {
    xxSmall: '10px',
    xSmall: '12px',
    small: '14px',
    medium: '16px',
    large: '18px',
    headerMedium: '20px',
    headerLarge: '24px',
    headerXxLarge: '32px',
};

export const bodyFontSizeNumber = {
    xxSmall: 10,
    xSmall: 12,
    small: 14,
    medium: 16,
    large: 18,
    headerMedium: 20,
    headerLarge: 24,
    headerXxLarge: 32,
};

export const subHeaderFontSize = {
    small: '12px',
    medium: '14px',
};

export const subHeaderFontSizeNumber = {
    small: 12,
    medium: 14,
};

export const headerFontSize = {
    xSmall: '14px',
    small: '16px',
    medium: '20px',
    large: '24px',
    xLarge: '28px',
    xxLarge: '32px',
    vinDiesel: '52px',
    xxxxLarge: '80px',
};

export const headerFontSizeNumber = {
    xSmall: 14,
    small: 16,
    medium: 20,
    large: 24,
    xLarge: 28,
    xxLarge: 32,
    vinDiesel: 52,
    xxxxLarge: 80,
};

export const lineHeight = {
    normal: 1.4,
    large: 1.1,
};
