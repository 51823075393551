/**
 * @generated SignedSource<<c338792899afbd4c1f4be9240d710bfc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySharedResultContainer_buyPage$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly item: {
        readonly serviceId: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"FavoritesProvider_item" | "SharedItemTile_item" | "useSbSharedItemTracking_item" | "useSharedUrgencySignals_item">;
      } | null;
    } | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_itemSearch" | "useSbSharedItemTracking_itemSearch">;
  readonly " $fragmentType": "BuySharedResultContainer_buyPage";
};
export type BuySharedResultContainer_buyPage$key = {
  readonly " $data"?: BuySharedResultContainer_buyPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedResultContainer_buyPage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    },
    {
      "kind": "RootArgument",
      "name": "showSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySharedResultContainer_buyPage",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemSearchQueryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSearchResult",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "serviceId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FavoritesProvider_item"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "useSharedUrgencySignals_item"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "useSbSharedItemTracking_item"
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "isTrade",
                      "variableName": "isTrade"
                    },
                    {
                      "kind": "Variable",
                      "name": "priceBookName",
                      "variableName": "priceBookName"
                    },
                    {
                      "kind": "Variable",
                      "name": "showSeller",
                      "variableName": "showSeller"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "SharedItemTile_item"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "e189637f2ef2a6a574d62ac0ba67db93";

export default node;
