import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { SeoLink } from 'dibs-elements/exports/SeoLink';
import LazyImage from 'dibs-react-lazy-image';
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';
import { useBuyCategoryTileData } from '../helpers/useBuyCategoryTileData';

import styles from './BuySharedCategoryTile.scss';

import { type BuySharedCategoryTile_categoryTile$key } from './__generated__/BuySharedCategoryTile_categoryTile.graphql';

type Props = {
    classNames: { tileStyle: string; imageStyle: string; titleStyle: string };
    categoryTile: BuySharedCategoryTile_categoryTile$key | null | undefined;
    currency: string;
    trackClick: (index: number) => void;
};

export const BuySharedCategoryTile: FC<Props> = ({
    classNames,
    categoryTile: categoryTileRef,
    currency,
    trackClick,
}) => {
    const categoryTile = useFragment(
        graphql`
            fragment BuySharedCategoryTile_categoryTile on SearchBrowseCategoryTiles {
                linkData {
                    path
                    text
                    isLinkable
                }
                image
                rank
                convertedAmountList {
                    amount
                    currency
                    linkData {
                        path
                        text
                        isLinkable
                    }
                }
            }
        `,
        categoryTileRef
    );

    const buyCategoryTileData = useBuyCategoryTileData({
        currency,
        categoryTile,
    });

    if (!buyCategoryTileData) {
        return null;
    }

    const { rank } = categoryTile || {};
    const { linkData, image } = buyCategoryTileData;
    const { tileStyle, imageStyle, titleStyle } = classNames;

    return (
        <SeoLink
            linkData={linkData}
            onClick={() => {
                if (rank) {
                    trackClick(rank - 1);
                }
            }}
            className={tileStyle}
        >
            <LazyImage offsetVertical={200} placeholderClass={styles.placeholderClass}>
                <img
                    className={imageStyle}
                    src={getSrcsetString(image, [200])}
                    alt={linkData?.text || ''}
                />
            </LazyImage>
            <div className={titleStyle}>{linkData.text}</div>
        </SeoLink>
    );
};
