/**
 * @generated SignedSource<<12a435619cb9939e3bb1b94d868e17cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySharedPersonalizedSearchItems_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_user" | "SharedMobileRelatedItem_user" | "SharedRelatedItemsWrapper_user">;
  readonly " $fragmentType": "BuySharedPersonalizedSearchItems_user";
};
export type BuySharedPersonalizedSearchItems_user$key = {
  readonly " $data"?: BuySharedPersonalizedSearchItems_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedPersonalizedSearchItems_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySharedPersonalizedSearchItems_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRelatedItemsWrapper_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedMobileRelatedItem_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c54129a7f7d3b07853b3d8260c9b7582";

export default node;
