import { initializeDBL } from 'dibs-buyer-layout/src/entries/index';
import { buyRespRootQuery } from '../../queries/buyRespRoot';
import { BuyRespLayout } from '../../buy/BuyRespLayout';
import { createEntry } from '../createEntry';
import { datadog, initSharedDatadogRum, sbTypes } from 'dibs-datadog/exports/datadog';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';
import { setDatadogABTestValues } from 'dibs-ab-tests/exports/clientAbTestV2';

import { defaultReducers, actionSanitizer } from '../../reducers/reducerUtils';
import { sbSharedReducers } from '../../reducers/sbSharedReducers';
const options = {
    reducers: {
        ...defaultReducers,
        ...sbSharedReducers,
    },
    actionSanitizer,
};

datadog.load().then(dd => {
    initSharedDatadogRum(dd, {
        device: 'resp',
        page: PAGE_TYPE.BROWSE,
        pageSubType: sbTypes.buy,
    });
    setDatadogABTestValues();
});

initializeDBL(options).then(({ store }) => {
    createEntry({
        Container: BuyRespLayout,
        store,
        query: buyRespRootQuery,
    });
});
