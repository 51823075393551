import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { SharedHeadTitleTag } from '../../shared/SharedHead/SharedHeadTitleTag';
import { SharedHeadMetaTags } from '../../shared/SharedHead/SharedHeadMetaTags';
import { SharedHeadScriptTags } from '../../shared/SharedHead/SharedHeadScriptTags';
import { SharedHeadLinkTags } from '../../shared/SharedHead/SharedHeadLinkTags';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { type BuySharedHead_buyPage$data } from './__generated__/BuySharedHead_buyPage.graphql';

type Props = { buyPage: BuySharedHead_buyPage$data };

const BuySharedHeadComponent: FC<Props> = ({ buyPage }) => {
    const { meta: metadata, edges: items } = buyPage;

    if (!metadata) {
        return null;
    }

    return (
        <>
            <SharedHeadTitleTag metadata={metadata} />
            <SharedHeadMetaTags
                metadata={metadata}
                items={(items || []).map(edge => edge?.node?.item).filter(filterFalsy)}
            />
            <SharedHeadScriptTags metadata={metadata} />
            <SharedHeadLinkTags metadata={metadata} />
        </>
    );
};

export const BuySharedHead = createFragmentContainer(BuySharedHeadComponent, {
    buyPage: graphql`
        fragment BuySharedHead_buyPage on ItemSearchQueryConnection {
            meta {
                ...SharedHeadTitleTag_metadata
                ...SharedHeadMetaTags_metadata
                ...SharedHeadScriptTags_metadata
                ...SharedHeadLinkTags_metadata
            }
            edges {
                node {
                    item {
                        ...SharedHeadMetaTags_items
                    }
                }
            }
        }
    `,
});
