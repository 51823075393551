import { type FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import EditorialModuleArticles from './EditorialModuleArticles';

import { type EditorialModule_editorialArticles$data } from './__generated__/EditorialModule_editorialArticles.graphql';

type Props = {
    editorialArticles: EditorialModule_editorialArticles$data;
    useWhiteBg?: boolean;
    headerClassName?: string;
};

const EditorialModuleComponent: FC<Props> = ({
    editorialArticles,
    useWhiteBg,
    headerClassName,
}) => {
    const articles = editorialArticles?.articles || [];
    if (!articles.length) {
        return null;
    }
    return (
        <EditorialModuleArticles
            articles={articles}
            useWhiteBg={useWhiteBg}
            headerClassName={headerClassName}
        />
    );
};

export const EditorialModule = createFragmentContainer(EditorialModuleComponent, {
    editorialArticles: graphql`
        fragment EditorialModule_editorialArticles on WordpressArticlesType {
            articles {
                ...EditorialModuleArticles_articles
            }
        }
    `,
});
