import { type FC, type ReactNode, useRef, useCallback, useMemo } from 'react';
import { useFragment, graphql } from 'react-relay';
import { trackEcommerce, eventNameConstants } from 'dibs-tracking';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';

import { type BuySharedCategoryTilesTracking_itemSearch$key as ItemSearch } from './__generated__/BuySharedCategoryTilesTracking_itemSearch.graphql';

type Props = {
    itemSearch: ItemSearch;
    children: ({ trackClick }: { trackClick: (index: number) => void }) => ReactNode;
};

export const BuySharedCategoryTilesTracking: FC<Props> = ({
    itemSearch: itemSearchRef,
    children,
}) => {
    const itemSearch = useFragment(
        graphql`
            fragment BuySharedCategoryTilesTracking_itemSearch on ItemSearchQueryConnection {
                categoryTiles {
                    trackingCreative
                    itemId
                    rank
                }
            }
        `,
        itemSearchRef
    );

    const trackingContainerRef = useRef(null);
    const { categoryTiles } = itemSearch;

    const promotions = useMemo(() => {
        return categoryTiles?.map(tile => {
            return {
                id: 'L2-result-tiles',
                name: 'Buy|Result Tiles',
                itemId: tile?.itemId || undefined,
                creative: tile?.trackingCreative || undefined,
                position: tile?.rank || undefined,
            };
        });
    }, [categoryTiles]);

    const trackImpression = useCallback(() => {
        trackEcommerce({
            type: 'promotionImpression',
            eventName: eventNameConstants.EVENT_VIEW_PROMOTION,
            promotions,
        });
    }, [promotions]);

    const trackClick = useCallback(
        (index: number) => {
            if (promotions?.[index]) {
                trackEcommerce({
                    type: 'promoClick',
                    eventName: eventNameConstants.EVENT_SELECT_PROMOTION,
                    promotions: [promotions[index]],
                });
            }
        },
        [promotions]
    );

    return (
        <div ref={trackingContainerRef}>
            <VisibilityTracker
                elementRef={trackingContainerRef}
                onVisibilityChange={({ isVisible }) => {
                    if (isVisible) {
                        trackImpression();
                    }
                }}
            />
            {children({ trackClick })}
        </div>
    );
};
