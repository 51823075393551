import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';
import { BuySharedCategoryTile } from './BuySharedCategoryTile';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';
import { BuySharedCategoryTilesTracking } from './BuySharedCategoryTilesTracking';

import styles from './BuyRespCategoryTiles.scss';

import { type BuyRespCategoryTiles_itemSearch$key as ItemSearch } from './__generated__/BuyRespCategoryTiles_itemSearch.graphql';

type Props = {
    itemSearch: ItemSearch;
};

export const BuyRespCategoryTiles: FC<Props> = ({ itemSearch: itemSearchRef }) => {
    const itemSearch = useFragment(
        graphql`
            fragment BuyRespCategoryTiles_itemSearch on ItemSearchQueryConnection {
                categoryTiles {
                    rank
                    ...BuySharedCategoryTile_categoryTile
                }
                ...BuySharedCategoryTilesTracking_itemSearch
            }
        `,
        itemSearchRef
    );

    const { categoryTiles } = itemSearch;

    if (!categoryTiles || !categoryTiles.length) {
        return null;
    }

    const tileClassNames = {
        tileStyle: styles.tile,
        imageStyle: styles.image,
        titleStyle: styles.title,
    };

    return (
        <CurrencyProvider
            render={({ currency }: { currency: string }) => (
                <BuySharedCategoryTilesTracking itemSearch={itemSearch}>
                    {({ trackClick }) => (
                        <div className={styles.tileContainer}>
                            {categoryTiles.map(categoryTile => (
                                <BuySharedCategoryTile
                                    classNames={tileClassNames}
                                    categoryTile={categoryTile}
                                    currency={currency}
                                    trackClick={trackClick}
                                    key={categoryTile?.rank}
                                />
                            ))}
                        </div>
                    )}
                </BuySharedCategoryTilesTracking>
            )}
        />
    );
};
