/**
 * @generated SignedSource<<1ad7cfa354bc838fe33f5d1b8ab15ae1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySharedBreadcrumbs_buyPage$data = {
  readonly breadcrumbs: ReadonlyArray<{
    readonly isLinkable: boolean | null;
    readonly path: string | null;
    readonly text: string | null;
  }> | null;
  readonly " $fragmentType": "BuySharedBreadcrumbs_buyPage";
};
export type BuySharedBreadcrumbs_buyPage$key = {
  readonly " $data"?: BuySharedBreadcrumbs_buyPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedBreadcrumbs_buyPage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySharedBreadcrumbs_buyPage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "breadcrumbs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLinkable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "4611cc498e13de6f6b4f1688e34f0cc1";

export default node;
