import { type FC, useEffect } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { SharedRelatedItems } from '../../shared/SharedRelatedItems/SharedRelatedItems';
import * as tracking from 'dibs-tracking';

import { type BuySharedRelatedPages_buyPage$data } from './__generated__/BuySharedRelatedPages_buyPage.graphql';

type Props = {
    buyPage: BuySharedRelatedPages_buyPage$data;
};

const BuySharedRelatedPagesComponent: FC<Props> = ({ buyPage }) => {
    const { relatedBuyPages, popularSearchesLink } = buyPage;
    const { isMobile } = useServerVarsContext();
    // relatedBuyPagesIdString is to prevent from useEffect firing twice
    const relatedBuyPagesIdString = relatedBuyPages?.map(page => page?.url).join();

    useEffect(() => {
        if (relatedBuyPagesIdString?.length) {
            tracking.trackEvent(
                {
                    category: 'navigation',
                    action: 'top related links impression',
                    label: 'buy page',
                },
                null
            );
        }
    }, [relatedBuyPagesIdString]);

    if (relatedBuyPages?.length) {
        return (
            <SharedRelatedItems
                relatedItems={relatedBuyPages}
                popularSearchesLink={popularSearchesLink}
                isFullWidth={isMobile}
                dataTn="buy-page-top-related-link-item"
                onClick={(_, event) =>
                    tracking.trackEvent(
                        {
                            category: 'navigation',
                            action: 'top related link click',
                            label: 'buy page',
                        },
                        event
                    )
                }
            />
        );
    }

    return null;
};

export const BuySharedRelatedPages = createFragmentContainer(BuySharedRelatedPagesComponent, {
    buyPage: graphql`
        fragment BuySharedRelatedPages_buyPage on ItemSearchQueryConnection {
            relatedBuyPages {
                anchorText
                url
            }
            popularSearchesLink {
                text
                path
            }
        }
    `,
});
