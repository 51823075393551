/**
 * @generated SignedSource<<eb22b94e05967edd66e5b061d0de1be0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditorialModule_editorialArticles$data = {
  readonly articles: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"EditorialModuleArticles_articles">;
  }> | null;
  readonly " $fragmentType": "EditorialModule_editorialArticles";
};
export type EditorialModule_editorialArticles$key = {
  readonly " $data"?: EditorialModule_editorialArticles$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditorialModule_editorialArticles">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditorialModule_editorialArticles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WordpressArticleType",
      "kind": "LinkedField",
      "name": "articles",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditorialModuleArticles_articles"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WordpressArticlesType",
  "abstractKey": null
};

(node as any).hash = "aa32942f9d348136f4dd8e62ae04d1f4";

export default node;
