/**
 * @generated SignedSource<<7f9750690fdaef7e142cffcb70e74e90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySharedCMSFAQ_buyPage$data = {
  readonly frequentlyAskedQuestions: ReadonlyArray<{
    readonly answer: string | null;
    readonly question: string | null;
  } | null> | null;
  readonly meta: {
    readonly header: string | null;
  } | null;
  readonly " $fragmentType": "BuySharedCMSFAQ_buyPage";
};
export type BuySharedCMSFAQ_buyPage$key = {
  readonly " $data"?: BuySharedCMSFAQ_buyPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedCMSFAQ_buyPage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySharedCMSFAQ_buyPage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MetaData",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "header",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BuyPageFrequentlyAskedQuestionType",
      "kind": "LinkedField",
      "name": "frequentlyAskedQuestions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "question",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "b79b1ccea0d0303ade4d7fda9dbdb019";

export default node;
