/**
 * @generated SignedSource<<93447b9f65db7c7877cba17a00871831>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineTopAppliedFilters_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly canBeDismissed: boolean | null;
    readonly localizedFilterName: string | null;
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly displayName: string | null;
      readonly hexCode: string | null;
      readonly linkReference: string | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly clearAppliedFiltersLinkReference: string | null;
  readonly filters: ReadonlyArray<{
    readonly localizedFilterName: string | null;
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly displayName: string | null;
      readonly linkReference: string | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"useMeasurementUnit_itemSearch">;
  readonly " $fragmentType": "SbRespRefineTopAppliedFilters_itemSearch";
};
export type SbRespRefineTopAppliedFilters_itemSearch$key = {
  readonly " $data"?: SbRespRefineTopAppliedFilters_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineTopAppliedFilters_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedFilterName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkReference",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineTopAppliedFilters_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMeasurementUnit_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clearAppliedFiltersLinkReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilter",
      "kind": "LinkedField",
      "name": "filters",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseFilterValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBeDismissed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hexCode",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "9b9f341cbf99db17acd75ff0dcecdc07";

export default node;
