/**
 * @generated SignedSource<<6a86bf571d708ffcd23c3d4ba45bf029>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BuySharedHead_buyPage$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly item: {
        readonly " $fragmentSpreads": FragmentRefs<"SharedHeadMetaTags_items">;
      } | null;
    } | null;
  } | null> | null;
  readonly meta: {
    readonly " $fragmentSpreads": FragmentRefs<"SharedHeadLinkTags_metadata" | "SharedHeadMetaTags_metadata" | "SharedHeadScriptTags_metadata" | "SharedHeadTitleTag_metadata">;
  } | null;
  readonly " $fragmentType": "BuySharedHead_buyPage";
};
export type BuySharedHead_buyPage$key = {
  readonly " $data"?: BuySharedHead_buyPage$data;
  readonly " $fragmentSpreads": FragmentRefs<"BuySharedHead_buyPage">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BuySharedHead_buyPage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MetaData",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedHeadTitleTag_metadata"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedHeadMetaTags_metadata"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedHeadScriptTags_metadata"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedHeadLinkTags_metadata"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemSearchQueryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSearchResult",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SharedHeadMetaTags_items"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "9b4724c571e7c8ca6773ec716656a8f6";

export default node;
