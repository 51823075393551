import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { FormattedMessage } from 'dibs-react-intl';
import dibsCss from 'dibs-css';
import classnames from 'classnames';

import { SaveSearchButton } from '../../components/global/SaveSearchButton/SaveSearchButton';
import { trackFollowButtonClick } from '../../utils/tracking/searchBrowse/trackFollowSearch';
import { getFilterValues } from '../../finding/SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { pageTypeConstants as PAGE_TYPES } from '../../constants/pageTypeConstants';
import { authLabels, locations } from '../../utils/tracking/shared/followSearchTracking';
import {
    checkRewardedSeller,
    checkTradeUserUnderTopLevelLoyaltyTiers,
} from 'dibs-buyer-layout/exports/sellerBrandingHelpers';
import { isSellerBrandingRemovalTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';
import { useSbSelector } from '../../reducers/useSbSelector';
import { useSharedSaveSearchContext } from '../SharedSaveSearchContext/SharedSaveSearchContext';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';

import { type SharedFollowSearchBanner_itemSearch$key } from './__generated__/SharedFollowSearchBanner_itemSearch.graphql';
import { type SharedFollowSearchBanner_user$key } from './__generated__/SharedFollowSearchBanner_user.graphql';

import styles from './SharedFollowSearchBanner.scss';

export type Props = {
    itemSearch: SharedFollowSearchBanner_itemSearch$key;
    user: SharedFollowSearchBanner_user$key;
    onComplete?: () => void;
};

export const SharedFollowSearchBanner: FC<Props> = ({
    itemSearch: itemSearchRef,
    user: userRef,
    onComplete = () => {},
}) => {
    const itemSearch = useFragment(
        graphql`
            fragment SharedFollowSearchBanner_itemSearch on ItemSearchQueryConnection {
                pageType
                appliedFilters {
                    name
                    values {
                        displayName
                    }
                }
                seller {
                    ...sellerBrandingHelpers_seller
                }
                ...SaveSearchButton_itemSearch
            }
        `,
        itemSearchRef
    );
    const user = useFragment(
        graphql`
            fragment SharedFollowSearchBanner_user on User {
                ...sellerBrandingHelpers_user
            }
        `,
        userRef
    );

    const isClient = useSbSelector(state => state.relayVariables.variables.isClient);
    const { isCategorySearch, isFollowing, showFollowButton, followSearchValue } =
        useSharedSaveSearchContext();

    if (!showFollowButton) {
        return null;
    }

    const { pageType, seller } = itemSearch || {};
    const appliedFilters = (itemSearch.appliedFilters || []).filter(filterNulls);

    let displayName = followSearchValue;
    const categoryL1 = getFilterValues(appliedFilters, 'categoryL1')[0]?.displayName;
    const categoryL2 = getFilterValues(appliedFilters, 'categoryL2')[0]?.displayName;
    const sellerName = getFilterValues(appliedFilters, 'sellerPk')[0]?.displayName;
    let messageType = isCategorySearch ? 'noQuotes' : 'quotes';

    if (isCategorySearch && !categoryL2 && pageType === PAGE_TYPES.MORE_FROM_SELLER && sellerName) {
        displayName = sellerName;
        if (categoryL1) {
            messageType = 'withCategory';
        }
    }

    const isSellerPage = pageType === PAGE_TYPES.MORE_FROM_SELLER || pageType === PAGE_TYPES.DEALER;
    const shouldShimmerSellerName = isSellerPage && !isClient;
    const shouldHideSellerName =
        isSellerPage &&
        isClient &&
        isSellerBrandingRemovalTestVariant() &&
        !checkRewardedSeller(seller) &&
        !checkTradeUserUnderTopLevelLoyaltyTiers(user || null);

    return (
        <div
            data-tn="follow-search-banner"
            className={classnames(
                dibsCss.bgBuyertertiary,
                dibsCss.textCenter,
                dibsCss.mtXlarge,
                dibsCss.mbSmall,
                dibsCss.pSmall,
                dibsCss.border,
                dibsCss.borderSolid,
                dibsCss.borderBuyertertiaryalt
            )}
        >
            {isFollowing ? (
                <div className={classnames(styles.copy, dibsCss.mAuto, dibsCss.pbSmall)}>
                    {shouldHideSellerName ? (
                        <FormattedMessage
                            id="sb.FollowSearch.startedFollowingTextHiddenSeller"
                            defaultMessage="Check your email for weekly updates."
                        />
                    ) : (
                        <FormattedMessage
                            id="sb.FollowSearch.startedFollowingText"
                            defaultMessage='Check your email for weekly updates in <bold>{messageType, select,
                                quotes {"{name}"}
                                withCategory {{name} {categoryL1}}
                                other {{name}}}</bold>.'
                            values={{
                                messageType,
                                bold: chunks => (
                                    <span
                                        data-tn="follow-search-value"
                                        className={classnames(
                                            styles.searchTerm,
                                            shouldShimmerSellerName && [
                                                styles.shimmer,
                                                dibsCss.textTransparent,
                                            ]
                                        )}
                                    >
                                        {chunks}
                                    </span>
                                ),
                                name: displayName,
                                categoryL1,
                            }}
                        />
                    )}
                </div>
            ) : (
                <>
                    <div
                        className={classnames(
                            styles.title,
                            dibsCss.mx0,
                            dibsCss.mtXsmall,
                            dibsCss.mbSmall
                        )}
                    >
                        <FormattedMessage
                            id="sb.SharedFollowSearchBanner.title"
                            defaultMessage="Get Updated with New Arrivals"
                        />
                    </div>
                    <div className={classnames(styles.copy, dibsCss.mAuto, dibsCss.pbSmall)}>
                        {shouldHideSellerName ? (
                            <FormattedMessage
                                id="sb.SharedFollowSearchBanner.textHiddenSeller"
                                defaultMessage="Save this vetted professional seller, and we’ll notify you when there are new listings in this category."
                            />
                        ) : (
                            <FormattedMessage
                                id="sb.SharedFollowSearchBanner.text"
                                defaultMessage='Save <bold>{messageType, select,
                                    quotes {"{name}"}
                                    withCategory {{name} {categoryL1}}
                                    other {{name}}}</bold>, and we’ll notify you when there are new listings in this category.'
                                values={{
                                    messageType,
                                    bold: chunks => (
                                        <span
                                            data-tn="follow-search-value"
                                            className={classnames(
                                                styles.searchTerm,
                                                shouldShimmerSellerName && [
                                                    styles.shimmer,
                                                    dibsCss.textTransparent,
                                                ]
                                            )}
                                        >
                                            {chunks}
                                        </span>
                                    ),
                                    name: displayName,
                                    categoryL1,
                                }}
                            />
                        )}
                    </div>
                </>
            )}
            <SaveSearchButton
                itemSearch={itemSearch}
                locationLabel={authLabels[locations.SEARCH_BROWSE]}
                onComplete={pageIsFollowed => {
                    trackFollowButtonClick({
                        isFollowing: pageIsFollowed,
                        label: 'bottom of results',
                    });
                    onComplete();
                }}
                size="medium"
            />
        </div>
    );
};

export default SharedFollowSearchBanner;
